::-webkit-scrollbar {
  width: 0px !important;
}

html, body{
  height:100% !important;
}

/* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Hide Number Input Roller */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important;
}

/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
/* Arabic Font-Family */
@font-face {
  font-family: "IBMPlexSans-Arabic-ExtraBold";
  src: url("./font/IBMPlexSansArabic-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-Bold";
  src: url("./font/IBMPlexSansArabic-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-SemiBold";
  src: url("./font/IBMPlexSansArabic-SemiBold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-Regular";
  src: url("./font/IBMPlexSansArabic-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-Italic";
  src: url("./font/IBMPlexSansArabic-Light.ttf");
}

/* All Font-Family */
@font-face {
  font-family: "IBMPlexSans-ExtraBold";
  src: url("./font/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Bold";
  src: url("./font/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-SemiBold";
  src: url("./font/IBMPlexSans-SemiBold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Regular";
  src: url("./font/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Italic";
  src: url("./font/IBMPlexSans-Italic.ttf");
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
html {
  height: 100%;
}

#root {
  height: 100%;
}


input:-webkit-autofill {
  background-color: none !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  background-image: none !important
}

:focus {
  outline: none !important;
}


/* Date picker */


input {
  padding: 13.5px 14px;
  width: 100%;
  font-family: "IBMPlexSans-SemiBold" !important;
  border: none;
}

input:focus-visible {
  outline: none !important;
}

.input-group-calender {
  padding-left: 15px;
  display: 'flex';
}


.react-datepicker__month-container {
  background-color: #fff;
  color: #969eac;
  padding: 10px;
  border: none !important;
}

.react-datepicker__month {
  padding: 1rem 0;
  margin:0 !important
}

.react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-weight: 'NunitoSans-SemiBold';
  padding: 0.2rem;

}

.react-datepicker {
  box-shadow: 0px 8px 24px #0717411F;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 'none';
}

.react-datepicker__day--selected {
  background-color: rgb(241, 90, 41) !important
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
  padding: 0 !important;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;

}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  width: 100%;
  padding: 10px;
  background-color: #F5F7FA;
}

.react-datepicker__month-select {
  width: 100%;
  background-color: #F5F7FA;
  border: none !important;
  font-family: "NotoSans-semiBold" !important;

  /* font-family: FontFamilySwitch().semiBold; */
}

select.react-datepicker__year-select:focus-visible {
  outline: none !important;
  /* border: none !important; */
}

.react-datepicker__month-select:focus-visible {
  outline: none !important;
  /* border: none !important; */
}

.react-datepicker__year-select {
  width: 100%;
  background-color: #F5F7FA;
  border: none !important;
  font-family: "N0toSans-semiBold" !important;
border-radius: 4px;
  /* font-family: FontFamilySwitch().semiBold; */
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  display: none !important;
}

.react-datepicker__navigation {
  display: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  color: #fff !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  color: #6A7888
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  color: #fff !important
}

.swiper {
  height: 100% !important;
}

.btn_label {
  width: 100%;
}

.recharts-surface {
  font-family: "NotoSans-Bold" !important;
  font-size: 10px;
}

/* #react-select-3-listbox{
  font-family: "NunitoSans-Bold" !important;
  font-size: 10px;
} */

.infinite-scroll-component {
  overflow: none !important;
  -webkit-scrollbar: none !important;
  box-shadow: none !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

#notistack-snackbar {
  font-family: "NotoSans-Bold" !important;

}

.bottom_bar {
  padding-bottom: calc(0.5 * env(safe-area-inset-bottom));
  cursor: pointer;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #F15A29 !important
}

.recharts-default-tooltip {
  border: 1px solid #E4E8EE;
  border-radius: 4px;
}

.recharts-tooltip-item-list {
  font-family: 'NotoSans-Bold';
  font-size: 12;
}

p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.owner_name{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word;
}
/* 
.MuiTabs-flexContainer{
  justify-content: center;
} */

.unordered_list{
  padding-left: 10px;
}

.password_strength{
  color: #98A0AC;
  font-family: 'NotoSans-SemiBold' !important;
  font-size: 12px;
}

html[dir="rtl"] {
  font-size: 110% !important;
}
html[dir="ltr"] {
  font-size: 100% !important;
}

.MuiTabs-flexContainer{
  justify-content: center;
}
/* Basic styling for the file input container */
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  padding: 12px 20px;
  border: 1px solid #F15A29;
  border-radius: 8px;
  font-weight: 'NunitoSans-SemiBold';
  background-color: #F15A29;
  color: #fff;
  border-color: #F15A29;
  width: 100%;
  text-align:center
}

/* Styling for when the container is hovered over */
.custom-file-upload:hover {
  background-color: #F15A29;
  color: #fff;
  border: 1px solid #F15A29;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: scale(0.8)
}
.react-datepicker__input-container input { 
 padding: 13.5px 26px !important;
}

.react-datepicker__day-name{
  font-family:'IBMPlexSans--Regular' !important ;
  font-size: 12px;

}
.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
  margin: 0 3px;
  border-radius: 4px !important;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
  font-family:'IBMPlexSans--Regular' !important ;
  font-size: 12px;
}

body{
  background-color: #F8F8F8 !important;
}

.qrcode {
  border: 5px solid #F15A29;
  border-radius: 8px;
  width: 240px;
}

.qrcode section > div {
  border: none !important;
  box-shadow: none !important;
}

.MuiTypography-root {
  letter-spacing: 0;
}